import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { observer } from "mobx-react";

import upArrow from "../asset/up_arrow.svg";
import downArrow from "../asset/down_arrow.svg";

import { useSnapshot } from "valtio";
import {
  LocaleState,
  MetadataState,
  fetchBrandsAndPumps,
  fetchPumpData,
} from "../store";

export const LanguageSelection = observer((props) => {
  const LocaleSnap = useSnapshot(LocaleState);
  const MetadataSnap = useSnapshot(MetadataState);
  const [expandedMarkets, setExpandedMarkets] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const changeSettings = (country, language) => {
    changeCountry(country);

    changeLanguage(language);

    props.toggleModal();
  };
  const changeCountry = (country) => {
    if (country === "SE") {
      localStorage.setItem("country", "SE");
      MetadataState.country = "SE";
    }
    if (country === "FI") {
      localStorage.setItem("country", "FI");
      MetadataState.country = "FI";
    }
    if (country === "NO") {
      localStorage.setItem("country", "NO");
      MetadataState.country = "NO";
    }
    if (country === "FR") {
      localStorage.setItem("country", "FR");
      MetadataState.country = "FR";
    }
    if (country === "DK") {
      localStorage.setItem("country", "DK");
      MetadataState.country = "DK";
    }

    fetchBrandsAndPumps();
    fetchPumpData();
  };

  const changeLanguage = (language) => {
    if (language === "sv") {
      localStorage.setItem("language", "sv");
      LocaleState.language = "sv";
    }

    if (language === "en") {
      localStorage.setItem("language", "en");
      LocaleState.language = "en";
    }
    if (language === "no") {
      localStorage.setItem("language", "no");
      LocaleState.language = "no";
    }
    if (language === "fr") {
      localStorage.setItem("language", "fr");
      LocaleState.language = "fr";
    }
  };
  return (
    <div className="ModalWrapper">
      <div className="ModalSelection">
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <div className="modalDivider">
          <div className="modalDropdown">
            <p className="title is-4">Select country</p>
            <select
              onClick={() => setExpandedMarkets(!expandedMarkets)}
              onBlur={() => setExpandedMarkets(false)}
              id="countrySelect"
              className="langSelect"
              defaultValue={MetadataSnap.country}
              style={{
                backgroundImage: expandedMarkets
                  ? `url(${upArrow})`
                  : `url(${downArrow})`,
              }}
            >
              <option value="FR">France</option>

              <option value="SE">Sweden</option>
              <option value="FI">Finland </option>
              <option value="NO">Norway </option>
            </select>
          </div>
          <div className="modalDropdown">
            <p className="title is-4">Select language</p>
            <select
              onClick={() => setExpanded(!expanded)}
              onBlur={() => setExpanded(false)}
              id="langSelect"
              className="langSelect"
              defaultValue={LocaleSnap.language}
              style={{
                backgroundImage: expanded
                  ? `url(${upArrow})`
                  : `url(${downArrow})`,
              }}
            >
              <option value="en">English</option>

              <option value="sv">Swedish</option>

              <option value="no">Norwegian</option>
              <option value="fr">French</option>
            </select>
          </div>
        </div>
        <button
          className="ModalButton"
          type="submit"
          onClick={() =>
            changeSettings(
              document.getElementById("countrySelect").value,
              document.getElementById("langSelect").value
            )
          }
        >
          Next
        </button>
      </div>
    </div>
  );
});
