import React, { useState, useEffect } from "react";
import { useSnapshot } from "valtio";

import { AiFillPrinter } from "react-icons/ai";
import { IoIosGlobe } from "react-icons/io";
import background from "../asset/header-background.jpg";
import logo from "../asset/logo-altech.svg";
import wave from "../asset/wave.svg";
import waveAqua from "../asset/waveAqua.svg";
import pump2Image from "../asset/Altech-pump2.png";
import { LanguageSelection } from "./LanguageSelection";
import { LocaleState, MetadataState } from "../store";
import { useHistory } from "react-router-dom";
import pumpImage from "../asset/header-picture.png";

export const Header = () => {
  const LocaleSnap = useSnapshot(LocaleState);
  const MetadataSnap = useSnapshot(MetadataState);

  const [languageModal, setLanguageModal] = useState(false);
  const [markets, setMarkets] = useState("");
  const [language, setLanguage] = useState("");

  const headlines = {
    sv: "Utbytesguide för pumpar",
    no: "Erstatningsguide for pumper",
    fr: "Guide de remplacement de pompes",
    en: "Replacement guide for pumps",
  };

  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const history = useHistory();

  const toggleModal = () => {
    setLanguageModal(!languageModal);
  };

  useEffect(() => {
    if (MetadataSnap.country === "SE") {
      setMarkets("Sweden");
    }
    if (MetadataSnap.country === "DK") {
      setMarkets("Denmark");
    }
    if (MetadataSnap.country === "FI") {
      setMarkets("Finland");
    }
    if (MetadataSnap.country === "NO") {
      setMarkets("Norway");
    }
    if (MetadataSnap.country === "FR") {
      setMarkets("France");
    }
  }, [MetadataSnap.country]);

  useEffect(() => {
    if (LocaleSnap.language === "en") {
      setLanguage("English");
    }
    if (LocaleSnap.language === "sv") {
      setLanguage("Swedish");
    }
    if (LocaleSnap.language === "no") {
      setLanguage("Norwegian");
    }
    if (LocaleSnap.language === "fr") {
      setLanguage("French");
    }
  }, [LocaleSnap.language]);

  const toHome = () => {
    history.push("/");
    window.location.reload();
  };

  return (
    <div>
      <header className="App-header">
        <div className="Header-top">
          <img
            src={logo}
            className="App-logo"
            alt="Altech logo"
            onClick={(e) => toHome()}
          />
        </div>

        <div className="Header-bottom">
          <p className="Title-paragraph">
            {headlines.hasOwnProperty(LocaleSnap.language)
              ? headlines[LocaleSnap.language]
              : ""}
          </p>
        </div>
        <div className="Header-image-div">
          <img src={pumpImage} className="oldPump" alt="Altech pump" />
        </div>
      </header>
      <section className="Language-bar">
        <div
          className="selectLang"
          onClick={() => {
            setLanguageModal(true);
          }}
        >
          <IoIosGlobe className="globeIcon" />
          <p>
            {markets} : {language}
          </p>
        </div>

        <AiFillPrinter
          className="printIcon"
          onClick={() => {
            window.print();
          }}
        />
      </section>
      {languageModal && <LanguageSelection toggleModal={toggleModal} />}
    </div>
  );
};
